import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
import defaulttheme from "../../customTheme.js";
import AddBoxIcon from "@material-ui/icons/AddBox";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Switch from "@material-ui/core/Switch";
import { getParkingMeterMapping } from "../../utils/ParkingPileApi";
import {
  addParkingMeter,
  addParkingMeterMappingPile,
  getParkingMeterById,
  deleteParkingMeter,
  updateParkingMeter,
} from "../../utils/ParkingMeterApi";
import {
  addParkingMeterAttribute,
  deleteParkingMeterAttribute,
  updateParkingMeterAttribute,
} from "../../utils/ParkingMeterApi";
import DescriptionIcon from "@material-ui/icons/Description";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DeleteConfirmationDialog from "../component/DeleteConfirmationDialog";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  button: {
    marginRight: theme.spacing(1),
    margin: "10px",
    borderRadius: "10px",
    color: "white",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff",
  },
}));

const ParkingMeter = ({ selectedPile }) => {
  const classes = useStyles();
  const { t, showSnackbar } = useContext(GlobalContext);
  // const { token } = React.useContext(AuthContext); // 取得Token

  const [selectedMeter, setSelectedMeter] = React.useState(null);

  const [openAddAttr, setOpenAddAttrDlg] = React.useState(false);
  const [attrName, setAttrName] = React.useState("");
  const [attrContent, setAttrContent] = React.useState("");
  const [attrEnable, setAttrEnable] = React.useState(true);
  const [openAddMeter, setOpenAddMeter] = React.useState(false);
  const [openEditMeter, setOpenEditMeter] = React.useState(false);
  const [inputMeterId, setInputMeterId] = React.useState("");
  const [inputMeterBrand, setInputMeterBrand] = React.useState("");
  const [inputMeterModel, setInputMeterModel] = React.useState("");
  const [inputMeterEnable, setInputMeterEnable] = React.useState(true);
  const [isConfirmDialogOpen, setConfirmDlgOpen] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [deleteFunction, setDeleteFunction] = React.useState(null);
  const [parkingMeters, setParkingMeter] = React.useState([]);
  const [selectedAttr, setSelectedAttr] = React.useState(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  useEffect(() => {
    // 在組件加載的時候得到資料
    RefreshParkingMeter();
  }, []);

  const RefreshParkingMeter = async () => {
    setOpenBackdrop(true);
    try {
      const response = await getParkingMeterMapping(selectedPile.parkPileId);
      const parkingMappingMeters = response.data.data;

      const parkingMeterWithData = await Promise.all(
        parkingMappingMeters.map(async (data) => {
     
          const meterData = await GetParkingMeter(data.parkingMeterId);
          return meterData;
        })
      );

      setParkingMeter(parkingMeterWithData);
      setOpenBackdrop(false);
    } catch (error) {
      console.error("query fail", error);
      setOpenBackdrop(false);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const GetParkingMeter = async (parkMeterId) => {
    try {
     
      const response = await getParkingMeterById(parkMeterId);
      const parkMeter = response.data.data;
      return parkMeter;
    } catch (error) {
      console.error("query fail", error);
      return null;
    }
  };

  const handleMeterRowClick = (meter) => {
    setSelectedMeter(meter);
  };

  const handleOpenAddMeter = () => {
    setOpenAddMeter(true);
  };

  const handleSaveMeter = async () => {
    try {
      const response = await addParkingMeter({
        manufacturerDeviceId: inputMeterId,
        brand: inputMeterBrand,
        model: inputMeterModel,
        isEnabled: inputMeterEnable,
      });

      if (response.isSuccess === true) {
        const parkMeterId = response.data.parkingMeterId;
        const response2 = await MeterAddMappingPile(parkMeterId);
        if (response2 !== null) RefreshParkingMeter();
      }
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "新增資料失敗",
        severity: "error",
      });
    }

    setInputMeterBrand("");
    setInputMeterModel("");
    setInputMeterId("");
    setInputMeterEnable(true);
    setOpenAddMeter(false);
  };

  const MeterAddMappingPile = async (meterId) => {
    try {
      const response = await addParkingMeterMappingPile(meterId, {
        parkPileId: selectedPile.parkPileId,
      });
      const mapping = response.data.data;
      return mapping;
    } catch (error) {
      console.error("add fail", error);
      return null; //
    }
  };

  const handleCloseAddMeter = () => {
    setOpenAddMeter(false);
  };

  const handleDeleteMeter = async (meter) => {
    console.log("delete meter");
    if (meter === null) return;

    try {
      const response = await deleteParkingMeter(meter.parkingMeterId);
      if (response.isSuccess) RefreshParkingMeter();
    } catch (error) {
      console.error("delete fail", error);
      showSnackbar({
        message: "刪除失敗",
        severity: "error",
      });
    }
  };

  const handleOpenEditMeter = () => {
    if (selectedMeter === null) {
      showSnackbar({
        message: "請先選擇停車表",
        severity: "error",
      });

      return;
    }

    setInputMeterBrand(selectedMeter.brand);
    setInputMeterModel(selectedMeter.model);
    setInputMeterId(selectedMeter.manufacturerDeviceId);
    setInputMeterEnable(selectedMeter.isEnabled);

    setOpenEditMeter(true);
  };

  const handleCloseEditMeter = () => {
    setOpenEditMeter(false);
  };

  const handleUpdateMeter = async () => {
    try {
      const response = await updateParkingMeter(selectedMeter.parkingMeterId, {
        manufacturerDeviceId: inputMeterId,
        brand: inputMeterBrand,
        model: inputMeterModel,
        isEnabled: inputMeterEnable,
        parkingMeterAttributes: selectedMeter.parkingMeterAttributes,
      });
      if (response.isSuccess) RefreshParkingMeter();
    } catch (error) {
      console.error("update fail", error);
      showSnackbar({
        message: "更新失敗",
        severity: "error",
      });
    }

    handleCloseEditMeter();
    setInputMeterBrand("");
    setInputMeterModel("");
    setInputMeterId("");
    setInputMeterEnable(true);
  };

  const handleOpenAddAttr = () => {
    setOpenAddAttrDlg(true);
  };

  const handleCloseAddAttr = () => {
    setAttrContent("");
    setAttrName("");
    setAttrEnable(true);
    setOpenAddAttrDlg(false);
  };

  const handleSaveAttr = async () => {
    try {
      const response = await addParkingMeterAttribute({
        parkingMeterId: selectedMeter.parkingMeterId,
        name: attrName,
        content: attrContent,
        isEnabled: attrEnable,
      });
      if (response.isSuccess) RefreshParkingMeter();
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "新增資料失敗",
        severity: "error",
      });
    }

    handleCloseAddAttr();
  };

  const handleDeletAttr = async (attr) => {
    if (attr === null) return;

    try {
      const response = await deleteParkingMeterAttribute(
        attr.parkingMeterAttributeId
      );
      if (response.isSuccess) RefreshParkingMeter();
    } catch (error) {
      console.error("Delete fail", error);
      showSnackbar({
        message: "刪除失敗",
        severity: "error",
      });
    }
  };

  const handeleEditMeter = async () => {
    try {
      const response = await updateParkingMeterAttribute(
        selectedAttr.parkingMeterAttributeId,
        {
          parkingMeterId: selectedMeter.parkingMeterId,
          name: attrName,
          content: attrContent,
          isEnabled: attrEnable,
        }
      );
      if (response.isSuccess) RefreshParkingMeter();
    } catch (error) {
      console.error("update fail", error);
      showSnackbar({
        message: "更新資料失敗",
        severity: "error",
      });
    }

    handleCloseAddAttr();
  };

  const handleClickAttrChip = (attr) => {
    setSelectedAttr(attr);
    setAttrName(attr.name);
    setAttrContent(attr.content);
    setAttrEnable(attr.isEnabled);
    setOpenAddAttrDlg(true);
  };

  const handleOpenConfirmDialog = (item, onDeleteFunction) => {
    console.log("open delete dlg");
    if (item === null) return;
    setConfirmDlgOpen(true);
    setItemToDelete(item);
    setDeleteFunction(() => onDeleteFunction);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDlgOpen(false);
    setItemToDelete("");
    setDeleteFunction(null);
  };

  const RenderSearch = (
    <>
      <Grid container spacing={3}>
        <Grid item container xs={12} justifyContent="flex-end">
          <Box display="flex" alignItems="center" style={{ margin: "20px" }}>
            {parkingMeters.length === 0 && (
              <Button
                variant="contained"
                startIcon={<AddBoxIcon />}
                className={classes.button}
                style={{ backgroundColor: defaulttheme.buttonColor.green }}
                onClick={handleOpenAddMeter}
              >
                Add
              </Button>
            )}
            {parkingMeters.length !== 0 && (
              <>
                <Button
                  variant="contained"
                  startIcon={<UpdateIcon />}
                  className={classes.button}
                  style={{ backgroundColor: defaulttheme.buttonColor.grey }}
                  onClick={handleOpenEditMeter}
                >
                  Edit
                </Button>

                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  className={classes.button}
                  style={{ backgroundColor: defaulttheme.buttonColor.red }}
                  onClick={() =>
                    handleOpenConfirmDialog(selectedMeter, handleDeleteMeter)
                  }
                >
                  delete
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const RenderParkingMeter = (
    <Paper>
      <TableContainer component={Paper} style={{ marginTop: "10px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
              <TableCell
                key="name"
                align="left"
                style={{ color: "white", fontSize: 16, width: "20%" }}
              >
                設備ID
              </TableCell>
              <TableCell
                key="brand"
                align="left"
                style={{ color: "white", fontSize: 16, width: "20%" }}
              >
                設備廠牌
              </TableCell>
              <TableCell
                key="model"
                align="left"
                style={{ color: "white", fontSize: 16, width: "20%" }}
              >
                設備型號
              </TableCell>
              <TableCell
                key="attr"
                align="left"
                style={{ color: "white", fontSize: 16, width: "20%" }}
              >
                設備屬性
              </TableCell>
              <TableCell
                key="action"
                style={{ color: "white", fontSize: 16, width: "5%" }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parkingMeters
              ? parkingMeters.map((meter, index) => (
                <>
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "10px",
                    }}
                    onClick={() => handleMeterRowClick(meter)}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedMeter &&
                          selectedMeter.parkingMeterId === meter.parkingMeterId
                          ? "#bad3e8"
                          : "white",
                    }}
                  >
                    <TableCell
                      key="name"
                      align="left"
                      style={{ fontSize: 16, width: "20%" }}
                    >
                      {meter.isEnabled
                        ? meter.manufacturerDeviceId
                        : meter.manufacturerDeviceId + "(停用)"}
                    </TableCell>
                    <TableCell
                      key="brand"
                      align="left"
                      style={{ fontSize: 16, width: "20%" }}
                    >
                      {meter.brand}
                    </TableCell>
                    <TableCell
                      key="model"
                      align="left"
                      style={{ fontSize: 16, width: "20%" }}
                    >
                      {meter.model}
                    </TableCell>
                    <TableCell
                      key="attr"
                      style={{ fontSize: 16, width: "20%" }}
                    >
                      {meter.attributes
                        ? meter.attributes.map((attr) => (
                          <Chip
                            key={attr.parkingMeterAttributeId}
                            icon={<DescriptionIcon />}
                            label={attr.name}
                            color={attr.isEnabled ? "primary" : "default"}
                            clickable
                            onClick={() => handleClickAttrChip(attr)}
                            onDelete={() =>
                              handleOpenConfirmDialog(attr, handleDeletAttr)
                            }
                            style={{ margin: "3px" }}
                          />
                        ))
                        : null}
                    </TableCell>

                    <TableCell
                      key="action"
                      align="right"
                      style={{ fontSize: 16, width: "20%" }}
                    >
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={handleOpenAddAttr}
                      >
                        <AddIcon />
                      </Fab>
                    </TableCell>
                  </TableRow>
                </>
              ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const RenderAddMeterDlg = (
    <>
      <Dialog
        open={openAddMeter}
        onClose={handleCloseAddMeter}
        aria-labelledby="addform-dialog-title"
      >
        <DialogTitle id="addform-dialog-title">新增停車表</DialogTitle>
        <DialogContent>
          <form className={classes.root}>
            <div>
              <TextField
                autoFocus
                margin="normal"
                id="manufacturerDeviceId"
                label="設備ID"
                onChange={(e) => setInputMeterId(e.target.value)}
              />
            </div>

            <TextField
              margin="normal"
              id="brand"
              label="廠牌"
              onChange={(e) => setInputMeterBrand(e.target.value)}
            />
            <TextField
              margin="normal"
              id="model"
              label="型號"
              onChange={(e) => setInputMeterModel(e.target.value)}
            />
            <Typography component="form">
              <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                style={{ margin: "10px" }}
              >
                <Grid item>停用</Grid>
                <Grid item>
                  <Switch
                    checked={inputMeterEnable}
                    onChange={(e) => setInputMeterEnable(e.target.checked)}
                    name="isEnabled"
                    color="primary"
                  />
                </Grid>
                <Grid item>啟用</Grid>
              </Grid>
            </Typography>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSaveMeter}>
            確定
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseAddMeter}
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const RenderEditMeterDlg = (
    <>
      <Dialog
        open={openEditMeter}
        onClose={handleCloseEditMeter}
        aria-labelledby="addform-dialog-title"
      >
        <DialogTitle id="addform-dialog-title">修改停車表</DialogTitle>
        <DialogContent>
          <form className={classes.root}>
            <div>
              <TextField
                autoFocus
                margin="normal"
                id="manufacturerDeviceId"
                label="設備ID"
                onChange={(e) => setInputMeterId(e.target.value)}
                value={inputMeterId}
              />
            </div>

            <TextField
              margin="normal"
              id="brand"
              label="廠牌"
              onChange={(e) => setInputMeterBrand(e.target.value)}
              value={inputMeterBrand}
            />
            <TextField
              margin="normal"
              id="model"
              label="型號"
              onChange={(e) => setInputMeterModel(e.target.value)}
              value={inputMeterModel}
            />
            <Typography component="form">
              <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                style={{ margin: "10px" }}
              >
                <Grid item>停用</Grid>
                <Grid item>
                  <Switch
                    checked={inputMeterEnable}
                    onChange={(e) => setInputMeterEnable(e.target.checked)}
                    name="isEnabled"
                    color="primary"
                    value={inputMeterEnable}
                  />
                </Grid>
                <Grid item>啟用</Grid>
              </Grid>
            </Typography>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateMeter}
          >
            確定
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseEditMeter}
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const RenderAttrDlg = (
    <Dialog
      open={openAddAttr}
      onClose={handleCloseAddAttr}
      aria-labelledby="addattrform-dialog-title"
    >
      <DialogTitle id="addattrform-dialog-title">新增屬性</DialogTitle>
      <DialogContent>
        <h3> {selectedMeter ? selectedMeter.name : null}</h3>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="名稱"
          fullWidth={true}
          onChange={(e) => setAttrName(e.target.value)}
          value={attrName}
        />
        <TextField
          margin="dense"
          id="content"
          label="內容"
          fullWidth={true}
          onChange={(e) => setAttrContent(e.target.value)}
          value={attrContent}
        />

        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>停用</Grid>
            <Grid item>
              <Switch
                checked={attrEnable}
                onChange={(e) => setAttrEnable(e.target.checked)}
                name="isEnabled"
                color="primary"
              />
            </Grid>
            <Grid item>啟用</Grid>
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={selectedAttr ? handeleEditMeter : handleSaveAttr}
        >
          確定
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCloseAddAttr}
        >
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className={classes.root}>
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        style={{ zIndex: 9999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <h3>停車表管理</h3>
      {RenderSearch}
      {RenderParkingMeter}
      {RenderAddMeterDlg}
      {RenderEditMeterDlg}
      {RenderAttrDlg}
      <DeleteConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onDelete={deleteFunction}
        item={itemToDelete}
      />
    </div>
  );
};

export default ParkingMeter;
