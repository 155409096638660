const isDev = process.env.NODE_ENV === "production" ? false : true;

const devHost = "https://pms-prod.southeastasia.cloudapp.azure.com";

// console.log("process", process, process.env);

export const ROOT_URL = isDev
  ? devHost
  : "";

export const version = "v1.0.0.1016";
