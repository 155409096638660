import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import defaulttheme from "../../customTheme.js";
import AddIcon from "@material-ui/icons/Add";



import { Button, Checkbox, Chip, DialogContent, DialogContentText, Typography,Fab } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";


import Pagination from '@material-ui/lab/Pagination';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getUsers, addUser, deleteUser, updateUser, addUserAttrbute, updateUserAttribute, deleteUserAttribute} from "../../utils/UserApi.js";
import { getRoleById, getRoles } from "../../utils/RoleApi.js";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteConfirmationDialog from "../component/DeleteConfirmationDialog";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  button: {
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "5px",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff",
  },
  dialog: {
    zIndex: theme.zIndex.drawer + 1,
  },
  tableRow: {
    fontSize: "16px",
    fontWeight:"bold"
  },
}));

const User = () => {
  const classes = useStyles();
  const { t, showSnackbar } = useContext(GlobalContext);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  
  const [openDlg, setOpenDlg] = React.useState(false);
  const [page, setPage] = React.useState(1); // 初始頁碼為1
  const [total, setTotal] = React.useState(0);
  const [users, setUsers] = React.useState([]);
  const [roles, setRoles] = React.useState([]);

  const [inputName, setInputName] = React.useState("");
  const [inputAccount, setInputAccount] = React.useState("");
  const [inputPassword, setInputPassword] = React.useState("");
  const [inputPassword2, setInputPassword2] = React.useState("");
  const [isEmptyInputName, setEmptyInputName] = React.useState(false);
  const [isEmptyInputAccount, setEmptyInputAccount] = React.useState(false);
  const [isEmptyInputPw, setEmptyInputPw] = React.useState(false);
  const [isEmptyInputPw2, setEmptyInputPw2] = React.useState(false);
  const [isPwNotEqual, setPwNotEqual] = React.useState(false);
  const [selectedRoleIds, setSelectedRoleIds] = React.useState([]);
  const [isConfirmDialogOpen, setConfirmDlgOpen] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [deleteFunction, setDeleteFunction] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [openAddAttr, setOpenAddAttr] = React.useState(false);
  const [attrName, setAttrName] =React.useState("");
  const [attrContent, setAttrContent] = React.useState("");
  const [attrEnable, setAttrEnable] = React.useState(true);
  const [selectedAttr, setSelectedAttr] = React.useState(null);  


  const rowsPerPage = 10;


  useEffect(() => {
    // 在組件加載的時候得到資料
    GetUsers();
    GetRoles();
  }, []);


  const GetUsers = async(newPage = 1)=>{
    try
    {
      const res = await getUsers(rowsPerPage*(newPage-1), rowsPerPage);
      const users = res.data.data;
      setTotal(res.data.total);
      const userInfos = await Promise.all(
        users.map( async(user) =>{
          const roles = await GetRolesByIds(user.roleIds);
          return{
              userInfo: user,
              roleInfos : roles
          };
        })
      )

      setUsers(userInfos);
      
    }
    catch(error)
    {
      console.log(error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  }

  const GetRolesByIds = async(roleIds) =>{
    const roles = await Promise.all(
      roleIds.map(async(id) =>{
        const res =await getRoleById(id);
        return res.data.data;
      }
     )
    )
    return roles;
  }

 
  const GetRoles = async()=>{
    try
    {
      const res = await getRoles();
      const roles = res.data.data;
      setRoles(roles);
      
    }
    catch(error)
    {
      console.log(error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  }


  const handleChangePage = (newPage) => {
    setPage(newPage);
    GetUsers(newPage);
  };


  const handleAddDlgOpen=()=>{
    setOpenDlg(true);
  }

  const handleCloseDlg=()=>{
    setOpenDlg(false);
    setSelectedRoleIds([]);
    setInputName("");
    setInputPassword("");
    setInputAccount("");
    setInputPassword2("");
    setCurrentUser(null);

  }

  const OnHandleSaveUser = async()=>{
    if(inputName.trim()==="")
    {
      setEmptyInputName(true);
      return;
    }
    
    if(inputAccount.trim()==="")
    {
      setEmptyInputAccount(true);
      return;
    }

    if(inputPassword.trim()==="")
    {
      setEmptyInputPw(true);
      return;
    }

    if(inputPassword2.trim()==="")
    {
      setEmptyInputPw2(true);
      return;
    }
     
    if(inputPassword2 !== inputPassword)
     {
        setPwNotEqual(true);
        return;
     }

     try{
        const res = await addUser({
          name: inputAccount,
          displayName: inputName,
          isEnabled: true,
          roleIds: selectedRoleIds,
          credentials:[ {
            credentialType: "password",
            content: inputPassword,
            isEnabled:true
          }]
        })

        if(res.isSuccess)
        {
          showSnackbar({
            message: "新增成功",
            severity: "info",
          });

          GetUsers();
          handleCloseDlg();
        }
        else
        {
          showSnackbar({
            message: "新增失敗",
            severity: "error",
          });
        }
     }
     catch(error)
     {
      console.log(error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
     }


  }

  const getErrorHelperText=()=>{
    if(isEmptyInputPw2)
      return "不得為空";

    else if(isPwNotEqual)  
      return "密碼不相符";

    else
      return "";
  }

  const OnChangeRoleEnable=(roleId, checked)=>{
    setSelectedRoleIds( preSelectIds =>{
      if(checked)
      {
        if(!preSelectIds.includes(roleId))
          return [...preSelectIds, roleId];
        else
          return preSelectIds;
      }
      else
      {
        if(preSelectIds.includes(roleId))
          return preSelectIds.filter(x=>x!=roleId);
        else
          return preSelectIds;
      }
    })

  }

  const handleOpenDeleteConfirmDlg = (item, onDeleteFunction) =>{
    setConfirmDlgOpen(true);
    if (item === null) return;
    setConfirmDlgOpen(true);
    setItemToDelete(item);
    setDeleteFunction(() => onDeleteFunction);
  
  }
  
  const handleCloseConfirmDialog = ()=>{
      setConfirmDlgOpen(false);
      setItemToDelete("");
      setDeleteFunction(null);
  }

  const handleDeleteUser= async(user)=>{
    try{
      console.log(user);
      const res = await deleteUser(user.userId);
      if(res.isSuccess)
        {
          showSnackbar({
            message: "刪除成功",
            severity: "info",
          });

          GetUsers();
          handleCloseDlg();
        }
        else
        {
          showSnackbar({
            message: "刪除失敗",
            severity: "error",
          });
        }
    }
    catch(error)
    {
      console.log(error);
      showSnackbar({
        message: "刪除失敗",
        severity: "error",
      });
    }
  }

  const OnChangeUserEnable=async(user, enable) =>{
    try
    {
      
      const res = await updateUser(user.userId, {
        name: user.username,
        displayname: user.displayName,
        isEnabled: !enable
      })

      if(res.isSuccess)
        {
          showSnackbar({
            message: "更新成功",
            severity: "info",
          });

          GetUsers();
          handleCloseDlg();
        }
        else
        {
          showSnackbar({
            message: "更新失敗",
            severity: "error",
          });
        }
    }
    catch(error)
    {
      showSnackbar({
        message: "更新失敗",
        severity: "error",
      });
    }
  }

  const handleOpenEditDlg=(user, roles)=>{
    
    setCurrentUser(user);
    roles.forEach( element=>{
      const {roleId} = element;
      setSelectedRoleIds( preSelectedIds=>{
        if(!preSelectedIds.includes(roleId))
            return [...preSelectedIds, roleId];
          else
            return preSelectedIds;
      })
    })

    setInputAccount(user.username);
    setInputName(user.displayName);
    
    setOpenDlg(true);
  }

  const OnHandleEditUser = async()=>{
    if(inputName.trim()==="")
    {
      setEmptyInputName(true);
      return;
    }
    
    if(inputAccount.trim()==="")
    {
      setEmptyInputAccount(true);
      return;
    }

    const new_credentails = [];
    if(inputPassword.trim() !=="")
    { 
      if(inputPassword2 !== inputPassword)
      {
          setPwNotEqual(true);
          return;
      }
      new_credentails =[{
        "credentialType": "password",
        "content": inputPassword,
        "isEnabled": true
      }]
    }

    try
    {
      
      const res = await updateUser(currentUser.userId, {
        name: currentUser.username,
        displayname: inputName,
        isEnabled: currentUser.isEnabled,
        roleIds: selectedRoleIds,
        credentails: new_credentails
      })

      if(res.isSuccess)
        {
          showSnackbar({
            message: "更新成功",
            severity: "info",
          });

          GetUsers();
          handleCloseDlg();
        }
        else
        {
          showSnackbar({
            message: "更新失敗",
            severity: "error",
          });
        }
    }
    catch(error)
    {
      showSnackbar({
        message: "更新失敗",
        severity: "error",
      });
    }


  }

  const handleOpenAddAttr = (user)=>{
    console.log(user);
    setCurrentUser(user);
    setOpenAddAttr(true);
  }

  const handleOpenEditAttr = (user,attr)=>{
    setCurrentUser(user);
    setSelectedAttr(attr);
    setAttrName(attr.name);
    setAttrContent(attr.content);
    setAttrEnable(attr.isEnabled);
    setOpenAddAttr(true);
  }

 const handleCloseAddAttr =()=>{
  setOpenAddAttr(false);
  setCurrentUser(null);
  setSelectedAttr(null);
  setAttrName("");
  setAttrContent("");
  setAttrEnable(true);
 }

 const handleSaveAttr = async()=>{
    try
    {
      const res = await addUserAttrbute({
        userId: currentUser.userId,
        name: attrName,
        content: attrContent,
        isEnabled: attrEnable
      })

      if(res.isSuccess)
      {
        showSnackbar({
          message: "新增成功",
          severity: "info",
        });
        GetUsers();
      }
      else
      {
        showSnackbar({
          message: "新增失敗",
          severity: "error",
        });
      }

    }
    catch(error)
    {
      console.log(error);
      showSnackbar({
        message: "新增失敗",
        severity: "error",
      });
    }
    handleCloseAddAttr();

 }

 const handleEditAttr = async()=>{
    try
    {
      const res = await updateUserAttribute(selectedAttr.userAttributeId, {
        userId: currentUser.userId,
        name: attrName,
        content: attrContent,
        isEnabled: attrEnable
      });

      if(res.isSuccess)
      {
        showSnackbar({
          message: "更新成功",
          severity: "info",
        });
        GetUsers();
      }
      else
      {
        showSnackbar({
          message: "更新失敗",
          severity: "error",
        });
      }

    }
    catch(error)
    {
      console.log(error);
      showSnackbar({
        message: "更新失敗",
        severity: "error",
      });
    }
    handleCloseAddAttr();
 }

 const handleDeleteAttr = async(attr)=>{
  try
  {
    console.log(attr);
     const res = await deleteUserAttribute(attr.userAttributeId);
    
    if(res.isSuccess)
    {
      showSnackbar({
        message: "刪除成功",
        severity: "info",
      });
      GetUsers();
    }
    else
    {
      showSnackbar({
        message: "刪除失敗",
        severity: "error",
      });
    }

  }
  catch(error)
  {
    console.log(error);
    showSnackbar({
      message: "更新失敗",
      severity: "error",
    });
  }
} 


  const RenderAttrDlg = (
    <Dialog
      open={openAddAttr}
      onClose={handleCloseAddAttr}
      aria-labelledby="addattrform-dialog-title"
    >
      <DialogTitle id="addattrform-dialog-title">{selectedAttr?"修改屬性":"新增屬性"}</DialogTitle>
      <DialogContent>
        <h3> { currentUser? currentUser.username : null}</h3>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="名稱"
          fullWidth={true}
          onChange={(e) => setAttrName(e.target.value)}
          value={attrName}
        />
        <TextField
          margin="dense"
          id="content"
          label="內容"
          fullWidth={true}
          onChange={(e) => setAttrContent(e.target.value)}
          value={attrContent}
        />
  
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>停用</Grid>
            <Grid item>
              <Switch
                checked={attrEnable}
                onChange={(e) => setAttrEnable(e.target.checked)}
                name="isEnabled"
                color="primary"
              />
            </Grid>
            <Grid item>啟用</Grid>
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={selectedAttr ? handleEditAttr : handleSaveAttr}
        >
          確定
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCloseAddAttr}
        >
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );
  
 

  const RenderAddDialog =(
    <>
    <Dialog
      maxWidth="md"
      onClose={handleCloseDlg}
      aria-labelledby="simple-dialog-title"
      open={openDlg}
      fullWidth= {true}
    >
      <DialogTitle id="simple-dialog-title">{currentUser?"修改使用者":"新增使用者"}</DialogTitle>
      <DialogContent>
      <Typography variant="h6" gutterBottom>
        使用者資訊
      </Typography>
      <Grid container spacing={3}>
        <Grid container item  xs={6}>
          <Grid item xs={12} >
            <TextField
              required
              id="username"
              name="username"
              label="使用者名稱"
              fullWidth
              value={inputName}
              error={isEmptyInputName}
              helperText ={isEmptyInputName?"不得為空":""}
              onChange={(e)=>{setInputName(e.target.value); setEmptyInputName(false);}}
              InputProps={{
                style: {
                  fontSize: '24px', 
                  textAlign: 'center',
                   fontWeight: 'bold',
                  
                },
              }}  
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="useraccount"
              name="useraccount"
              label="使用者帳號"
              value={inputAccount}
              error={isEmptyInputAccount}
              helperText ={isEmptyInputAccount?"不得為空":""}
              disabled ={currentUser?true:false}
              fullWidth
              onChange={(e)=>{setInputAccount(e.target.value); setEmptyInputAccount(false)}}
              InputProps={{
                style: {
                  fontSize: '24px', 
                  textAlign: 'center',
                   fontWeight: 'bold',
                  
                },
              }}  
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="password"
              name="password"
              label="使用者密碼"
              error={isEmptyInputPw}
              helperText ={isEmptyInputPw?"不得為空":""}
              fullWidth
              type="password"
              onChange={(e)=>{setInputPassword(e.target.value); setEmptyInputPw(false)}}
              InputProps={{
                style: {
                  fontSize: '24px', 
                  textAlign: 'center',
                   fontWeight: 'bold',
                  
                },
              }}  
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="rewrite"
              name="rewrite"
              label="再次輸入密碼"
              fullWidth
              error={isEmptyInputPw2 || isPwNotEqual}
              helperText ={getErrorHelperText()}
              type="password"
              onChange={(e)=>{setInputPassword2(e.target.value); setEmptyInputPw2(false)}}
              InputProps={{
                style: {
                  fontSize: '24px', 
                  textAlign: 'center',
                   fontWeight: 'bold',
                  
                },
              }}  
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
        <TableContainer component={Paper} style={{ margin: "10px" }}>
          <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: defaulttheme.blue.delta }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: "10px",
                  }}
                >
                  {rowHeader_dlg.map((header) => (
                    <TableCell
                      key={header}
                      align="left"
                      style={{ color: "white", fontSize: "16px" , width: `${header.width}%`}}
                    >
                      {header.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  roles?roles.map((role, index)=>(
                    <TableRow
                      key={role.roleId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      style={{
                      backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd"}}
                    >
                          <TableCell component="th" scope="row" className={classes.tableRow}>
                              {role.roleName}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.tableRow}>
                            <FormGroup>
                            <FormControlLabel
                              control={ <Switch
                                checked = {selectedRoleIds.includes(role.roleId)} 
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                 onChange={(e)=>OnChangeRoleEnable(role.roleId, e.target.checked)}
                              />}
                           
                            />                   
                          </FormGroup>
                          </TableCell>

                    </TableRow>
                  )):null
                }

              </TableBody>
          </Table>
        </TableContainer>


        </Grid> 
      </Grid>     
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleCloseDlg}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" 
              onClick={currentUser?OnHandleEditUser:OnHandleSaveUser}
            >
              OK
            </Button>
           
          </DialogActions>
        </Dialog>
      </>

  );


  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        style={{ zIndex: 9999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container justifyContent="flex-end">
        <Grid item xs={6}>
          <h2 style={{ margin: "20px" }}>使用者列表</h2>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            style={{ margin: "10px" }}
            onClick={()=> handleAddDlgOpen()}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      {RenderAddDialog}
      {RenderAttrDlg}
      <TableContainer component={Paper} style={{ margin: "10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
              {rowHeader.map((header) => (
                <TableCell
                  key={header}
                  align="left"
                  style={{ color: "white", fontSize: "16px" , width: `${header.width}%`}}
                >
                  {header.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              users?users.map((user,index) =>(
                <TableRow
                  key={user.userInfo.userId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                  backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd"}}
                >
                   <TableCell component="th" scope="row" className={classes.tableRow}>
                      {user.userInfo.username}
                   </TableCell>
  
                   <TableCell component="th" scope="row" className={classes.tableRow}>
                      {user.userInfo.displayName}
                   </TableCell>
                  
                   <TableCell component="th" scope="row"  >
                   {
                      user.roleInfos? user.roleInfos.map((role) =>(
                        role?
                        (<Chip
                          key={role.roleId}
                          label={role.roleName}
                          color="primary"
                  
                          style={{ margin: "3px", fontSize:"36px" }}
                         
                        ></Chip>  ):null
                      )):null

                    }
                   </TableCell>
                   <TableCell>   
                      <Grid container>
                        <Grid item xs={8}>
                        {
                          user.userInfo.attributes? user.userInfo.attributes.map((attr) =>(
                            <Chip
                              key={attr.userAttributeId}
                              label={attr.name}
                              color={attr.isEnabled?"primary":"default"}
                              onClick= {()=>handleOpenEditAttr(user.userInfo, attr)}
                              onDelete={()=>handleOpenDeleteConfirmDlg(attr, handleDeleteAttr)}
                              style={{ margin: "3px", fontSize:"36px" }}
                            
                            ></Chip>
                          )):null
                        }
                        </Grid>
                        <Grid item xs={4}>
                          <Fab
                            size="small"
                            color="primary"
                            aria-label="add"
                            onClick={()=>handleOpenAddAttr(user.userInfo)}
                            
                          >
                            <AddIcon />
                          </Fab>
                        </Grid>
                      </Grid>
                   </TableCell>
                   <TableCell component="th" scope="row" className={classes.tableRow}>
                   <Grid container>
                    <Grid item>
                        <IconButton
                          color="primary"
                          aria-label="delete"
                          component="span"
                          className={classes.button}
                          style={{
                            backgroundColor: defaulttheme.buttonColor.blue,
                            color: "white",
                          }}
                           onClick={() => handleOpenEditDlg(user.userInfo, user.roleInfos)}
                        >
                              <EditIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                          color="primary"
                          aria-label="history"
                          component="span"
                          className={classes.button}
                          style={{
                            backgroundColor: "red",
                            color: "white",
                          }}
                         onClick={() => handleOpenDeleteConfirmDlg(user.userInfo, handleDeleteUser)}
                        >
                          <DeleteIcon />
                        </IconButton>         
                    </Grid>
                    <Grid item>                       
                        <FormGroup>
                          <FormControlLabel
                            control={ <Switch
                              checked = {user.userInfo.isEnabled} 
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              onChange={(e)=>OnChangeUserEnable(user.userInfo, user.userInfo.isEnabled)}
                            />}
                            label="啟用"
                            labelPlacement="top"
                          />                   
                    </FormGroup>
                    </Grid>

                  </Grid>                    
                   </TableCell>



                </TableRow>
              )):null
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination 
          count={ total>0 ? Math.ceil(total / rowsPerPage) : 0} 
          page={page} 
          onChange={(event, newPage) => handleChangePage(newPage)}
           />
      </div>
      <DeleteConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onDelete={deleteFunction}
        item={itemToDelete}
      />
    </>
  );
};

const rowHeader = [ { name:"使用者帳號", width: 20},  {name: "使用者名稱",  width: 20} , {name: "角色",  width: 20}, {name: "屬性",  width: 20}, {name:"操作", width: 20}];

const rowHeader_dlg = [ { name:"角色名稱", width: 60},  {name: "啟用",  width: 40} ];


export default User;
