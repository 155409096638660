import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import defaulttheme from "../../customTheme.js";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { getLocations } from "../../utils/LocationApi";
import { getAllParkingSpaces, GetParkingSpaceMappingWithPile } from "../../utils/ParkingSpaceApi";
import {
  getParkingTickets,
  getParkingTicketsByIds,
  getParkingTicketHistoriesById,
  getParkingTicketSnapshotList,
  getParkingTicketsByConditions,
} from "../../utils/ParkingTicketApi";

import { getParkingPiles,getParkingMeterMapping } from "../../utils/ParkingPileApi.js";

import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import ImageIcon from "@material-ui/icons/Image";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getAllParkingLots } from "../../utils/ParkingLotApi";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { ROOT_URL } from "../../config/api.js";
import Pagination from "@material-ui/lab/Pagination";
import ReactImageMagnify from "react-image-magnify";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";

import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FilterListIcon from "@material-ui/icons/FilterList";
import CancelIcon from  "@material-ui/icons/Cancel";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "5px",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  chip: {
    margin: "3px",
    backgroundColor: defaulttheme.buttonColor.blue, // 設定背景顏色為與按鈕相同
    color: "white", // 設定字體顏色
    '&:hover': {
      backgroundColor: defaulttheme.buttonColor.blue, // 滑鼠懸停時仍使用相同的背景顏色
      opacity: 0.9, // 可選：稍微改變不透明度以示反饋
    },
  },
}));

const theme = createTheme({
  typography: {
    fontSize: 16, // 設置文字大小
  },
});

const TicketReport = () => {
  const classes = useStyles();
  const { t, showSnackbar } = useContext(GlobalContext);
  // const { token } = useContext(AuthContext); // 取得Token

  const [locations, setLocations] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState("");
  const [parkingSpaces, setParkingSpaces] = React.useState([]);
  const [parkingTickets, setParkingTicket] = React.useState([]);
  const [selectedParkingSpaceId, setSelectedParkingSpace] = React.useState("");
  const [selectedTicketData, setSelectedTicketData] = React.useState("");
  const [openDlg, setOpenDlg] = React.useState(false);
  const [openSearchDlg, setOpenSearchDlg] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [parkingLots, setParkingLots] = React.useState([]);
  const [parkingLotsInLoc, setParkingLotsInLoc] = React.useState([]);
  const [selectedParkingLotId, setSelectedParkingLotId] = React.useState("");
  const [selectedParkingSpacesInLot, setSelectedParkingSpaceInLot] =
    React.useState([]);
  const [ticketHistories, setTicketHistories] = React.useState([]);
  const [imageItems, setImageItems] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [page, setPage] = React.useState(1); // 初始頁碼為1
  const [total, setTotal] = React.useState(0);
  const [SearchByTime, setSearchByTime] = React.useState(false);
  const [SearchByParkingSpace, setSearchByParkingSpace] = React.useState(false);
  const [SearchByLicensePlate, setSearchByLicensePlate] = React.useState(false);
  const [SearchByStatus, setSearchByStatus] = React.useState(false);
  const [startSearchTime, setStartTime] = React.useState("");
  const [endSearchTime, setEndTime] = React.useState("");
  const [LicensePlate, setLicensePlate] = React.useState("");
  const [TicketStatus, setTicketStatus] = React.useState("");

  const rowsPerPage = 10;

  useEffect(() => {
    // 在組件加載的時候得到資料
    RefreshLocation();
    RefreshParkingSpace();
    RefreshParkingLots();
    GetParkingTicketFiltered();
    setStartTime(getDefaultDateTime(true));
    setEndTime(getDefaultDateTime());
  }, []);

  const RefreshLocation = async () => {
    
    getLocations()
      .then((response) => {
        const locations = response.data.data;

        setLocations(locations);
      })
      .catch((error) => {
      
        showSnackbar({
          message: "取得資料失敗",
          severity: "error",
        });
      });
  };

  const RefreshParkingSpace = async () => {
    try {
      let allSpaces = [];
      let limit = 10;

      var response = await getAllParkingSpaces(0, limit);
      var resp = response.data;
      allSpaces = resp.data;

      if (resp.total > resp.amount) {
        response = await getAllParkingSpaces(0, resp.total);
        resp = response.data;
        allSpaces = resp.data;
      }

      setParkingSpaces(allSpaces);
      return allSpaces;
    } catch (error) {
    
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const RefreshParkingLots = async () => {
    try {
      let allParkingLots = [];
      let limit = 10;
      var response = await getAllParkingLots(0, limit);
      var resp = response.data;
      allParkingLots = resp.data;

      if (resp.total > resp.amount) {
        response = await getAllParkingLots(0, resp.total);
        resp = response.data;
        allParkingLots = resp.data;
      }

      setParkingLots(allParkingLots);
      return allParkingLots;
    } catch (error) {
   
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const handleSearchClick = async () => {
    setOpenSearchDlg(true);
  };

  const GetParkingTicketFiltered = async (newPage = 1) => {
    setOpenBackdrop(true);

    try {
      // 先更新所有停車空間和停車區域的資料
      const parkSpaces = await RefreshParkingSpace();
      const parkLots = await RefreshParkingLots();

      let spacesToQuery = GetSpaceToQuery(parkLots, parkSpaces);
      const parkingSpaceIds = spacesToQuery.map(
        (space) => space.parkingSpaceId
      ); // 提取所有 parkingSpaceId

      let startTime = "";
      let endTime = "";
      if (SearchByTime) {
        startTime = new Date(startSearchTime).toISOString();
        endTime = new Date(endSearchTime).toISOString();
      }

      const response = await getParkingTicketsByConditions(
        startTime,
        endTime,
        SearchByLicensePlate ? LicensePlate : "",
        SearchByStatus ? TicketStatus : "",
        parkingSpaceIds,
        rowsPerPage * (newPage - 1),
        rowsPerPage
      );
      let allTickets = [];
      allTickets = response.data.data;

      // 計算票據總數
      const totalTickets = response.data.total;

      // 將票據與對應的停車格和停車區域資料結合
      const ticketWithSpaceAndLot = await Promise.all(
        allTickets.map(async (ticket) => {
          const space = await GetParkingSpace(
            parkSpaces,
            ticket.parkingSpaceId
          );
          const lot = space
            ? await GetParkingLot(parkLots, space.parkingLotId)
            : null;

            const meter = space?
            await GetParkingMeter(space)
            : null;

          const images = await GetTicketImage(ticket.parkingTicketId);

          const filteredImages = images.filter(
            (image) =>
              image.reason === "FirstReport" || image.reason === "Checkpoint"
          );
          // 根據 time 欄位進行升序排序
          filteredImages.sort((a, b) => new Date(a.time) - new Date(b.time));
       
          return {
            ticket: ticket,
            parkingSpace: space,
            parkingLot: lot,
            parkingMeter: meter,
            checkPointImage: filteredImages,
          };
        })
      );

      setParkingTicket(ticketWithSpaceAndLot);
      setTotal(totalTickets); // 設置總票數
    

      setOpenBackdrop(false);
    } catch (error) {
  
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
      setParkingTicket([]);
      setOpenBackdrop(false);
    }
  };

  const GetSpaceToQuery = (parkLots, parkSpaces) => {
    let spacesToQuery = [];

    if (SearchByParkingSpace === false) return spacesToQuery;

    // 如果 ParkingLot 是 "all"，則篩選出該 Location 內的所有 ParkingSpaces
    if (selectedParkingLotId === "all") {
      const lotsInLocation = parkLots.filter(
        (lot) => lot.locationId === selectedLocation
      );
      spacesToQuery = parkSpaces.filter((space) =>
        lotsInLocation.some((lot) => lot.parkingLotId === space.parkingLotId)
      );
    } else if (selectedParkingSpaceId === "all") {
      // 如果 ParkingSpace 是 "all"，則篩選出對應 ParkingLot 下的所有 ParkingSpaces
      spacesToQuery = parkSpaces.filter(
        (space) => space.parkingLotId === selectedParkingLotId
      );
    } else {
      // 否則只查詢特定的 ParkingSpace
      spacesToQuery = parkSpaces.filter(
        (space) => space.parkingSpaceId === selectedParkingSpaceId
      );
    }

    return spacesToQuery;
  };

  const GetAllParkingTicket = async (newPage = 1) => {
    setOpenBackdrop(true);
    try {
      const parkSpaces = await RefreshParkingSpace();
      const parkLots = await RefreshParkingLots();
      const response = await getParkingTickets(
        "",
        rowsPerPage * (newPage - 1),
        rowsPerPage
      );
      const reports = response.data.data;
      setTotal(response.data.total);
      const ticketWithSpaceAndLot = await Promise.all(
        reports.map(async (report) => {
          const space = await GetParkingSpace(
            parkSpaces,
            report.parkingSpaceId
          );
          const Lot = space
            ? await GetParkingLot(parkLots, space.parkingLotId)
            : null;
            const meter = space?
            await GetParkingMeter(space)
            : null;

          const images = await GetTicketImage(report.parkingTicketId);
          const filteredImages = images.filter(
            (image) =>
              image.reason === "FirstReport" || image.reason === "CheckPoint"
          );
          // 根據 time 欄位進行升序排序
          filteredImages.sort((a, b) => new Date(a.time) - new Date(b.time));
       
          return {
            ticket: report,
            parkingSpace: space,
            parkingLot: Lot,
            parkingMeter: meter,
            checkPointImage: filteredImages,
          };
        })
      );

      setParkingTicket(ticketWithSpaceAndLot);

      setOpenBackdrop(false);
    } catch (error) {
      setParkingTicket([]);
      setOpenBackdrop(false);
    }
  };

  const GetParkingSpace = async (parkSpaces, parkingSpaceId) => {
    try {
      const parkSpace = parkSpaces.find(
        (space) => space.parkingSpaceId === parkingSpaceId
      );
  
      return parkSpace || null;
    } catch (error) {
      return null;
    }
  };

  const GetParkingLot = async (parkLots, parkingLotId) => {
    try {
      const parkLot = parkLots.find((lot) => lot.parkingLotId === parkingLotId);
      return parkLot || null;
    } catch (error) {
      return null;
    }
  };

  const GetParkingMeter = async (parkingSpace) => {
    try {
      let mappings = [];
      let limit = 10;
      var response = await GetParkingSpaceMappingWithPile(parkingSpace.parkingSpaceId);
      var resp = response.data;
      mappings = resp.data;
      
      if(mappings.length <= 0)
        return null;
      
      let parkingPileId = mappings[0].parkPileId;
      var response2 = await getParkingMeterMapping(parkingPileId);
      var resp2 = response2.data;
      let parkingMeters = resp2.data;
      if(parkingMeters != null && parkingMeters.length > 0)
        return parkingMeters[0];
      else
        return null;
    
    
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const handleRawData = async (ticketData) => {
    setSelectedTicketData(ticketData);

    setOpenBackdrop(true);
    await getParkingTicketHistoriesById(ticketData.ticket.parkingTicketId)
      .then((response) => {
        const histories = response.data.data;
        if (histories)
          histories.sort((a, b) => new Date(b.createAt) - new Date(a.createAt));

        setTicketHistories(histories);
        setOpenBackdrop(false);
      })
      .catch((error) => {
       
        setTicketHistories(null);
        setOpenBackdrop(false);
        showSnackbar({
          message: "取得資料失敗",
          severity: "error",
        });
      });

    setOpenDlg(true);
  };

  const handleCloseDlg = () => {
    setOpenDlg(false);
  };

  const handleCloseSearchDlg = () => {
    setOpenSearchDlg(false);

    setPage(1);
    //開始搜尋
    if (
      SearchByLicensePlate === false &&
      SearchByParkingSpace === false &&
      SearchByStatus === false &&
      SearchByTime === false
    )
      GetAllParkingTicket();
    else {
      GetParkingTicketFiltered();
    }
  };

  const handleClickPreview = async (ticketData) => {
    setSelectedTicketData(ticketData);

    setOpenBackdrop(true);
    const images = await GetTicketImage(ticketData.ticket.parkingTicketId);
  
    const imageList = await Promise.all(
      images.map(async (image, index) => {
        return {
          img: `${ROOT_URL}/api/v1/parkingTicket/${ticketData.ticket.parkingTicketId}/snapshot/${image.filename}`,
          author: "author",
          title: index,
          time: image.time,
          reason: image.reason,
        };
      })
    );

    setImageItems(imageList);

    setOpenBackdrop(false);

    setOpenPreview(true);
  };

  const GetTicketImage = async (ticketId) => {
    try {
      const response = await getParkingTicketSnapshotList(ticketId);
      return response.data.data;
    } catch (error) {
      return null;
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const ISO8601toLocalTime = (iso8601Str) => {
    const iso8601Date = new Date(iso8601Str);

    function formatLocalDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const formattedLocalDateTime = formatLocalDateTime(iso8601Date);
    return formattedLocalDateTime;
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    GetParkingTicketFiltered(newPage);
  };

  const handleLocationChange = (loc) => {
    setSelectedLocation(loc);

    if (loc === "all") {
      setParkingLotsInLoc([]);
      setSelectedParkingLotId("all");
      setSelectedParkingSpace("all");
      setSelectedParkingSpaceInLot("");
    } else {
      const filteredParkingLots = parkingLots
        .filter((lot) => lot.locationId === loc)
        .sort((a, b) => a.name.localeCompare(b.name)); // 按名稱升序排序;;

      setParkingLotsInLoc(filteredParkingLots);
      setSelectedParkingLotId("all");
      setSelectedParkingSpace("all");
    }
  };

  const handleParkingLotChange = (lot) => {
 

    setSelectedParkingLotId(lot);
    const filteredParkingSpaces = parkingSpaces
      .filter((space) => space.parkingLotId == lot)
      .sort((a, b) => a.name.localeCompare(b.name)); // 按名稱升序排序;

    setSelectedParkingSpaceInLot(filteredParkingSpaces);
    setSelectedParkingSpace("all");
  };

  const getSearchSpaceName = () => {
  

    let locName = "";
    if (selectedLocation === "all" || selectedLocation === "") locName = "全部";
    else {
      locations.forEach((loc) => {
        if (loc.childs.length > 0) {
     
          let child = loc.childs.find(
            (childLoc) => childLoc.locationId === selectedLocation
          );
        
          locName = child.name;
        }
      });
    }

    let parkingLotName = "";
    if (selectedParkingLotId === "all") parkingLotName = "全部";
    else {
      let parkingLot = parkingLots.find(
        (lot) => lot.parkingLotId === selectedParkingLotId
      );
      if (parkingLot) parkingLotName = parkingLot.name;
    }

    let parkingSpaceName = "";
    if (selectedParkingSpaceId === "all") parkingSpaceName = "全部";
    else {
      let parkingSpace = parkingSpaces.find(
        (space) => space.parkingSpaceId === selectedParkingSpaceId
      );
      if (parkingSpace) parkingSpaceName = parkingSpace.name;
    }

    return `${locName}-${parkingLotName}-${parkingSpaceName}`;
  };

  const ReanderPreviewImage = (
    <Dialog
      onClose={handleClosePreview}
      aria-labelledby="simple-dialog-title"
      open={openPreview}
      PaperProps={{
        style: {
          width: "50%",
          height: "50%",
          position: "absolute",
          top: "25%",
          left: "25%",
        },
      }}
    >
      <DialogTitle id="simple-dialog-title">{selectedTicketData.parkingLot ? selectedTicketData.parkingLot.name : ""} -
      {selectedTicketData.parkingSpace ? selectedTicketData.parkingSpace.name : ""}</DialogTitle>
      <DialogContent>
        <ImageList className={classes.imageList} cols={1}>
          {imageItems
            ? imageItems.map((item) => (
                <ImageListItem key={item.img} style={{ height: "auto" }}>
                  {
                    /* <img src={item.img} alt={item.title} /> */
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: item.title,
                          src: item.img,
                          isFluidWidth: true,
                          hoverOffSetX: 0,
                          hoverOffSetY: 0,
                        },
                        largeImage: {
                          src: item.img,
                          width: 1920,
                          height: 1080,
                        },
                        enlargedImagePosition: "over",
                        isActivatedOnTouch: false,
                        isHintEnabled: true,
                      }}
                    />
                  }
                  {/* <ImageComponent image={item.img}/> */}
                  <ImageListItemBar
                    title={ISO8601ToDateTime(item.time)}
                    position="top"
                    subtitle={item.reason}
                    className={classes.titleBar}
                    style={{ pointerEvents: "none" }}
                  />
                </ImageListItem>
              ))
            : null}
        </ImageList>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClosePreview}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderSelect = (
    <div>
      <FormControl className={classes.formControl} style={{ width: "100%" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper
              variant="outlined"
              style={{
                width: "100%", // 設定寬度
                height: "100px", // 設定高度
              }}
            >
              {SearchByTime ? (
                <Chip
                  key="searchbytime"
                  label={`${ISO8601toLocalTime(
                    startSearchTime
                  )} - ${ISO8601toLocalTime(endSearchTime)}`}
                  clickable
                  onDelete={() => {
                    setSearchByTime(false);
                  }}
                  className={classes.chip}
                  deleteIcon={<CancelIcon style={{ color: "white" }} />} // 設置刪除圖標顏色為白色
                />
              ) : null}
              {SearchByLicensePlate ? (
                <Chip
                  key="searchlicenseplate"
                  label={LicensePlate}
                  clickable
                  onDelete={() => {
                    setSearchByLicensePlate(false);
                    setLicensePlate("");
                  }}
                  className={classes.chip}
                  deleteIcon={<CancelIcon style={{ color: "white" }} />} // 設置刪除圖標顏色為白色
                />
              ) : null}
              {SearchByStatus ? (
                <Chip
                  key="searchstatus"
                  label={TicketStatus}
                  clickable
                  onDelete={() => {
                    setSearchByStatus(false);
                    setTicketStatus("");
                  }}
                  className={classes.chip}
                  deleteIcon={<CancelIcon style={{ color: "white" }} />} // 設置刪除圖標顏色為白色
                />
              ) : null}

              {SearchByParkingSpace ? (
                <Chip
                  key="searchparkingspace"
                  label={getSearchSpaceName()}
                  clickable
                  onDelete={() => {
                    setSearchByParkingSpace(false);
                    setSelectedParkingSpace("all");
                  }}
                  className={classes.chip}
                  deleteIcon={<CancelIcon style={{ color: "white" }} />} // 設置刪除圖標顏色為白色
                />
              ) : null}
            </Paper>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<FilterListIcon />}
              className={classes.button}
              style={{
                backgroundColor: defaulttheme.buttonColor.blue,
                color: "white",
              }}
              onClick={handleSearchClick}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );

  const RenderSearchDialog = (
    <Dialog
      maxWidth="xl"
      onClose={handleCloseSearchDlg}
      aria-labelledby="simple-dialog-title"
      open={openSearchDlg}
    >
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper style={{ padding: "20px" }}>
          <FormControl component="fieldset">
            <Grid container direction="column" spacing={2}>
              {/* Block 1 with Checkbox */}
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <Checkbox
                    color="primary"
                    onChange={(e) => setSearchByTime(e.target.checked)}
                    checked={SearchByTime}
                  />
                </Grid>
                <Grid item xs>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TextField
                        id="datetime-local"
                        label="起始時間"
                        type="datetime-local"
                        defaultValue={getDefaultDateTime(true)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ style: { fontSize: "16px" } }}
                        onChange={(e) => setStartTime(e.target.value)}
                        value={
                          SearchByTime
                            ? startSearchTime
                            : getDefaultDateTime(true)
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="datetime-local2"
                        label="結束時間"
                        type="datetime-local"
                        defaultValue={getDefaultDateTime()}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ style: { fontSize: "16px" } }}
                        onChange={(e) => setEndTime(e.target.value)}
                        value={
                          SearchByTime
                            ? endSearchTime
                            : getDefaultDateTime(true)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Block 2 with Checkbox */}
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <Checkbox
                    color="primary"
                    onChange={(e) => setSearchByParkingSpace(e.target.checked)}
                    checked={SearchByParkingSpace}
                  />
                </Grid>
                <Grid item xs>
                  <FormControl className={classes.formControl}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <label htmlFor="grouped-select">
                          {t("space_location")}
                        </label>
                        <Select
                          native
                          defaultValue=""
                          id="grouped-native-select"
                          onChange={(e) => handleLocationChange(e.target.value)}
                          style={{ width: "200px", marginLeft: "10px" }}
                          value={selectedLocation || "all"}
                        >
                          <option value="all">{t("all")}</option>
                          {locations && locations.length > 0 ? (
                            locations.map((loc, index) => (
                              <optgroup key={index} label={loc.name}>
                                {loc.childs && loc.childs.length > 0 ? (
                                  loc.childs.map((child, index2) => (
                                    <option
                                      key={index2}
                                      value={child.locationId}
                                    >
                                      {child.name}
                                    </option>
                                  ))
                                ) : (
                                  <option value={loc.locationId}>
                                    {loc.name}
                                  </option>
                                )}
                              </optgroup>
                            ))
                          ) : (
                            <option disabled>沒有資料</option>
                          )}
                        </Select>
                      </Grid>
                      <Grid item>
                        <label htmlFor="grouped-select">
                          {t("space_section")}
                        </label>
                        <Select
                          defaultValue=""
                          d="grouped-select"
                          onChange={(e) =>
                            handleParkingLotChange(e.target.value)
                          }
                          style={{ width: "200px", marginLeft: "10px" }}
                          value={selectedParkingLotId || "all"}
                        >
                          <MenuItem key="all" value="all">
                            {t("all")}
                          </MenuItem>
                          {parkingLotsInLoc
                            ? parkingLotsInLoc.map((d, index) => (
                                <MenuItem
                                  key={d.parkingLotId}
                                  value={d.parkingLotId}
                                >
                                  {d.name}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </Grid>
                      <Grid item>
                        <label htmlFor="grouped-select">選擇停車格</label>
                        <Select
                          defaultValue=""
                          d="grouped-select"
                          onChange={(e) =>
                            setSelectedParkingSpace(e.target.value)
                          }
                          style={{ width: "200px", marginLeft: "10px" }}
                          value={selectedParkingSpaceId || "all"}
                        >
                          <MenuItem key="all" value="all">
                            {t("all")}
                          </MenuItem>

                          {selectedParkingSpacesInLot
                            ? selectedParkingSpacesInLot.map((space, index) => (
                                <MenuItem value={space.parkingSpaceId}>
                                  {space.name}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Block 3 with Checkbox */}
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <Checkbox
                    color="primary"
                    onChange={(e) => setSearchByLicensePlate(e.target.checked)}
                    checked={SearchByLicensePlate}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="licenseplate"
                    label="車牌號碼"
                    style={{ marginLeft: "10px" }}
                    onChange={(e) => setLicensePlate(e.target.value)}
                    value={LicensePlate}
                  />
                </Grid>
              </Grid>

              {/* Block 4 with Checkbox */}
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <Checkbox
                    color="primary"
                    onChange={(e) => setSearchByStatus(e.target.checked)}
                    checked={SearchByStatus}
                  />
                </Grid>
                <Grid item xs>
                  <label>停車單狀態</label>
                  <Select
                    defaultValue=""
                    d="grouped-select"
                    onChange={(e) => setTicketStatus(e.target.value)}
                    style={{ width: "200px", marginLeft: "10px" }}
                    value={TicketStatus}
                  >
                    <MenuItem key="all" value="all">
                      {t("all")}
                    </MenuItem>
                    <MenuItem key="Created" value="Created">
                      Created
                    </MenuItem>
                    <MenuItem
                      key="TimingAccumulating"
                      value="TimingAccumulating"
                    >
                      TimingAccumulating
                    </MenuItem>
                    <MenuItem key="Closed" value="Closed">
                      Closed
                    </MenuItem>
                    <MenuItem
                      key="TicketCreateRetrying"
                      value="TicketCreateRetrying"
                    >
                      TicketCreateRetrying
                    </MenuItem>
                    <MenuItem
                      key="TicketCreateRetryLimitExceeded"
                      value="TicketCreateRetryLimitExceeded"
                    >
                      TicketCreateRetryLimitExceeded
                    </MenuItem>
                    <MenuItem
                      key="TicketCreationAborted"
                      value="TicketCreationAborted"
                    >
                      TicketCreationAborted
                    </MenuItem>
                    <MenuItem
                      key="TicketAtNoChargeTime"
                      value="TicketAtNoChargeTime"
                    >
                      TicketAtNoChargeTime
                    </MenuItem>
                    <MenuItem
                      key="VehicleTypeDisallowed"
                      value="VehicleTypeDisallowed"
                    >
                      VehicleTypeDisallowed
                    </MenuItem>
                    <MenuItem
                      key="TicketBeforeNoParkingTime"
                      value="TicketBeforeNoParkingTime"
                    >
                      TicketBeforeNoParkingTime
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </Paper>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseSearchDlg}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderDialog = (
    <Dialog
      maxWidth="xl"
      onClose={handleCloseDlg}
      aria-labelledby="simple-dialog-title"
      open={openDlg}
    >
   
      <DialogTitle id="simple-dialog-title">{selectedTicketData.parkingLot ? selectedTicketData.parkingLot.name : ""} -
        {selectedTicketData.parkingSpace ? selectedTicketData.parkingSpace.name : ""}
       </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: defaulttheme.blue.delta }}>
                <TableCell align="left">Time</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Content</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketHistories.map((history) => (
                <TableRow>
                  <TableCell align="left">
                    {ISO8601toLocalTime(history.createAt)}
                  </TableCell>
                  <TableCell align="left">{history.type}</TableCell>
                  <TableCell align="left">{history.content}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleCloseDlg}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <ThemeProvider theme={theme}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {RenderSelect}

      <h3 style={{ margin: "20px" }}>停車格開單資料</h3>

      <TableContainer component={Paper} style={{ margin: "10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
              {rowHeader.map((header) => (
                <TableCell
                  key={header}
                  align="left"
                  style={{ color: "white", fontSize: 16, fontWeight: "bold" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {parkingTickets.map((ticketData, index) => (
              <TableRow
                key={ticketData.ticket.parkingTicketId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd",
                }}
              >
                <TableCell component="th" scope="row">
                  {ticketData.parkingSpace ? ticketData.parkingSpace.name : ""}
                </TableCell>
                <TableCell align="left">
                  {ticketData.parkingLot ? ticketData.parkingLot.name : ""}
                </TableCell>
                <TableCell align="left">
                  {ticketData.parkingMeter ? ticketData.parkingMeter.manufacturerDeviceId : ""}
                </TableCell>
                <TableCell align="left">
                  {ticketData.ticket.plateNumber}
                </TableCell>
                <TableCell align="left">
                  {ticketData.ticket.carInAt
                    ? ISO8601toLocalTime(ticketData.ticket.carInAt)
                    : null}
                </TableCell>
                <TableCell align="left">
                  {ticketData.ticket.carOutAt
                    ? ISO8601toLocalTime(ticketData.ticket.carOutAt)
                    : null}
                </TableCell>
                <TableCell align="left">
                  {ticketData.ticket.parkingTicketStatus}
                </TableCell>
                <TableCell align="left">
                  {ticketData.checkPointImage.length > 0
                    ? ticketData.checkPointImage.map((image, index2) => (
                        <Tooltip title={ISO8601toLocalTime(image.time)}>
                          <CheckCircleIcon />
                        </Tooltip>
                      ))
                    : null}
                </TableCell>
                <TableCell align="left">
                  <IconButton
                    color="primary"
                    aria-label="rawdata"
                    component="span"
                    className={classes.button}
                    style={{
                      backgroundColor: defaulttheme.buttonColor.blue,
                      color: "white",
                    }}
                    onClick={() => handleRawData(ticketData)}
                  >
                    <MessageIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="upicture"
                    component="span"
                    className={classes.button}
                    style={{
                      backgroundColor: defaulttheme.buttonColor.blue,
                      color: "white",
                    }}
                    onClick={() => handleClickPreview(ticketData)}
                  >
                    <ImageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Pagination
          count={total > 0 ? Math.ceil(total / rowsPerPage) : 0}
          page={page}
          onChange={(event, newPage) => handleChangePage(newPage)}
        />
      </div>

      {RenderDialog}
      {ReanderPreviewImage}
      {RenderSearchDialog}
    </ThemeProvider>
  );
};

function GetIsoTime(datetime) {
  const currentLocalDate = datetime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const [month, day, year] = currentLocalDate.split("/");
  const formattedDate = `${year}-${month}-${day}`;

  const currentLocalTime = datetime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 使用24小时制
  });

  // 将日期和时间组合为ISO格式
  const currentLocalDateTime = `${formattedDate}T${currentLocalTime}`;
  return currentLocalDateTime;
}

function ISO8601ToDateTime(isoDateString) {
  const date = new Date(isoDateString);

  // 確保Date物件有效，並格式化日期和時間
  if (!isNaN(date.getTime())) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else {
    return "Invalid Date";
  }
}

function getDefaultDateTime(startOfDay = false) {
  const now = new Date();
  if (startOfDay) {
    now.setHours(0, 0, 0, 0); // 設定為今天的 0 點 0 分
  }

  // 將日期和時間格式化為本地時間的 YYYY-MM-DDTHH:mm 格式
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  const time = `${year}-${month}-${day}T${hours}:${minutes}`;

  return time;
}

const rowHeader = [
  "車格名稱",
  "停車路段",
  "設備編號",
  "車牌號碼",
  "入車時間",
  "出車時間",
  "開單狀態",
  "加簽狀態",
  "",
];

export default TicketReport;
