import React, { useEffect, useContext } from "react";
import AppRouter from "./routers/AppRouter.js";
import theme from "./customTheme.js";
import { ThemeProvider } from "@material-ui/styles";
import { AuthProvider, AuthContext, useAuth } from "./contexts/AuthContext";
import { GlobalProvider, GlobalContext } from "./contexts/GlobalContext";
import "./style/normalize.css";
import { signalRConnection, createSignalRConnection } from './utils/signalrService.js';
import { tokenIsValid, renewToken, token } from './utils/tokenHandler.js'
import refreshTokenWorkerImport from './worker/refreshTokenWorker';

const ShowSnakbar = ({ severity = "error", message = "" }) => {

  const globalContext = useContext(GlobalContext);
  const { showSnackbar } = globalContext;

  useEffect(() => {
    if (message != null && message !== "") {
      showSnackbar({ severity, message });
    }

  }, [severity, message]);


  return (<></>);
};



const App = () => {

  // const authContext = useContext(AuthContext);
  // const { tokenState } = authContext || {};

  // useEffect(()=>{
  //   console.log("Token value:", tokenState);
  //   if (tokenState   && !signalRConnection) {
  //     console.log("APP useEffect");
  //     createSignalRConnection();
  //   }
  // },[tokenState]);

  const [severity, setSeverity] = React.useState("info");
  const [message, setMessage] = React.useState("");



  useEffect(() => {
    if (localStorage.getItem("keep") == 1) {
      const refreshTokenWorker = new Worker(refreshTokenWorkerImport);
      refreshTokenWorker.postMessage({ type: 'start', jwt: token });

      refreshTokenWorker.onmessage = (e) => {
        const { type } = e.data;

        if (type === 'refreshToken') {
          // 重新整理 Token
          renewToken()
            .then((token) => {
              refreshTokenWorker.postMessage({
                type: 'start', jwt:
                  token
              });

              console.log("refresh token success");
            });
        }
        else if (type === 'getToke') {
          console.log("get token");
          refreshTokenWorker.postMessage({ type: 'start', jwt: token });
        }
      };

      return () => {
        refreshTokenWorker.terminate();
      };
    }
  }, []);



  useEffect(() => {
    // 檢查 Token 是否有效，並且建立 SignalR 連接
    const checkTokenAndConnectSignalR = async () => {
      if (tokenIsValid()) {
        // 如果 Token 有效，建立 SignalR 連接
        createSignalRConnection();
      }
    };

    checkTokenAndConnectSignalR();
  }, []); // 只在應用程式啟動時執行一次

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.on("OnParkingTicketEventArrive", (data) => {
        console.log(`${JSON.stringify(data)}`);
        // 在這裡處理接收到的訊息
        if (data.parkingTicketEvent.type === 2) {
          setMessage(`偵測倒車號重複的停車單, ticketId=${data.parkingTicket.parkingTicketId} spaceId=${data.parkingTicket.parkingSpaceId}`);
        }
      });

      return () => {
        // 在組件卸載時移除事件處理程序
        signalRConnection.off("OnParkingTicketEventArrive");
      }
    }
  }, [signalRConnection]);// 確保這個效果只運行一次




  return (
    <GlobalProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <ShowSnakbar severity={severity} message={message} />
          <AppRouter />
        </ThemeProvider>
      </AuthProvider>
    </GlobalProvider>
  );
}

export default App;
