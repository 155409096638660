import { jwtDecode } from "jwt-decode";
import { refeshToken } from "../utils/LoginApi";

let token = localStorage.getItem("token");
let keep = localStorage.getItem("keep") === "1";
let decodeToken = token ? jwtDecode(token) : null;

const tokenIsValid = () => {
  if (!token || !decodeToken) {
    return false;
  }

  // check if token is expired
  if (new Date() > new Date(decodeToken.exp * 1000)) {
    return false;
  } else return true;
};

const tokenIsExpiring = () => {
  // if decodeToken.exp is less then 10 minutes
  if (new Date(decodeToken.exp * 1000) - new Date() < 10 * 60 * 1000) {
    // call refresh token change new token
    // refeshToken(token).then((res) => {
    //   console.log("refeshToken success.");
    //   setToken(res.data.token);
    // });

    renewToken();

  } else return false;
};

const setToken = (newToken) => {
  console.log("setToken", newToken);
  token = newToken;
  decodeToken = newToken ? jwtDecode(newToken) : null;
  localStorage.setItem("token", newToken);

  // null means logout, clear related data
  if (newToken === null) {
    localStorage.removeItem("token");
  }
};

const setKeep = (newKeep) => {
  console.log("setKeep", newKeep);
  keep = newKeep;
  localStorage.setItem("keep", newKeep ? 1 : 0);

  if (newKeep === false || newKeep === null) {
    localStorage.removeItem("keep");
  }
};

const renewToken = () => {
  return refeshToken(token).then((res) => {
    console.log("refeshToken success.");
    setToken(res.data.token);
    return res.data.token;
  });
};

export { token, keep, tokenIsValid, tokenIsExpiring, setToken, setKeep, renewToken };
