
import React, { useEffect, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { green, red } from '@material-ui/core/colors';

import defaulttheme from "../../customTheme.js";

import { getHourRates } from "../../utils/ParkingMeterApi";
import { GlobalContext } from '../../contexts/GlobalContext.js';

const tableRowHeader = [
    { "key": "time_period.start", "name": "開始", "type": "time" },
    { "key": "time_period.end", "name": "結束", "type": "time" },
    { "key": "car_in_photo", "name": "入格拍攝", "type": "boolean" },
    { "key": "car_out_photo", "name": "離格拍攝", "type": "boolean" },
    { "key": "billing", "name": "計費時段", "type": "boolean" },
    { "key": "no_parking", "name": "禁停時段", "type": "boolean" },
    { "key": "tech_enforcement", "name": "科技執法", "type": "boolean" },
    { "key": "heartbeat", "name": "心跳間隔(分)", "type": "number" },
    { "key": "time_limit", "name": "限時停車(分)", "type": "number" },
    { "key": "signature", "name": "加簽間隔(分)", "type": "number" },
    { "key": "first_upload", "name": "首次回報(分)", "type": "number" },
    { "key": "grace_period", "name": "離場緩衝(分)", "type": "number" },
    { "key": "parking_buffer_time", "name": "入格緩衝(分)", "type": "number" }
];

const Page = ({ open, setOpen, data }) => {
    const { setIsLoading } = useContext(GlobalContext);
    const [tableData, setTableData] = React.useState([]);
    const [sectionName, setSectionName] = React.useState("");
    const [spaceName, setSpaceName] = React.useState("");
    const [deviceId, setDeviceId] = React.useState("");


    const flatternData = (data) => {
        const flattenedData = data.map((item) => {
            const flattenedItem = { ...item };
            flattenedItem["time_period.start"] = item.time_period.start;
            flattenedItem["time_period.end"] = item.time_period.end;
            delete flattenedItem.time_period;

            return flattenedItem;
        });
        return flattenedData;
    };

    const getDisplayValue = (type, value) => {
        switch (type) {
            case "time":
                return new Date(value).toLocaleString();
            case "boolean":
                return value ? <CheckIcon style={{ color: green[700] }} /> : <ClearIcon style={{ color: red[700] }} />;
            case "number":
            default:
                return `${value}`
        }
    }

    useEffect(() => {
        const { sectionName, spaceName, deviceId, brand } = data;
        setSectionName(sectionName);
        setSpaceName(spaceName);
        setDeviceId(deviceId);
        setIsLoading(true);
        getHourRates(brand, deviceId).then((response) => {

            let hourRates = response.data.data;
            if (hourRates !== undefined && hourRates !== null && hourRates.length > 0) {
                setTableData(flatternData(hourRates));
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, [data]);


    return (
        <Dialog
            maxWidth="xl"
            onClose={() => setOpen(false)}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">時段表 - {sectionName} {spaceName} - {deviceId}</DialogTitle>
            <DialogContent>
                {Object.keys(tableData).length > 0 && (<>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow
                                    style={{ backgroundColor: defaulttheme.blue.delta }}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        height: "10px",
                                    }}>
                                    {tableRowHeader.map((header) => (
                                        <TableCell
                                            key={header.key}
                                            align="center"
                                            style={{ color: "white", fontSize: 16, fontWeight: "400" }}
                                        >
                                            {header.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((dataRow, dataRowIndex) => {

                                    return (
                                        <TableRow
                                            key={dataRowIndex}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            style={{
                                                backgroundColor: dataRowIndex % 2 === 0 ? "white" : "#d8f1fd",
                                            }}
                                        >
                                            {tableRowHeader.map((header, headerIndex) => (
                                                <TableCell
                                                    key={header.key + '_' + headerIndex}
                                                    align="center"
                                                >
                                                    {
                                                        getDisplayValue(header.type, dataRow[header.key])
                                                    }
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
                )}
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => setOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Page;